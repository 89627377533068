import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";

const AdminSidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();

  const adminToken = localStorage.getItem("admintoken");
  const adminname = localStorage.getItem("adminname");
  const adminemail = localStorage.getItem("adminemail");

  const { adminLogout } = useAuth();
  if (!adminToken) {
    adminLogout();
  }

  const isTabActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <img src={`${process.env.PUBLIC_URL}/imgs-alu/logo.png`} alt="" />
        </Link>

        <ul className="sidebar-list">
          <li className={isTabActive("/adminDashboard")}>
            <div className="title">
              <Link to="/adminDashboard" className="link">
                <i className="bi bi-ui-checks-grid"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
          </li>
          <li className={isTabActive("/customerDetails")}>
            <div className="title">
              <Link to="/customerDetails" className="link">
                <i className="bi bi-people"></i>
                <span className="name">Customer Management</span>
              </Link>
            </div>
          </li>

          <li className={isTabActive("/productsDetails")}>
            <div className="title">
              <Link to="/productsDetails" className="link">
                <i className="bi bi-box-seam"></i>
                <span className="name">Product Management</span>
              </Link>
            </div>
          </li>
          <li className={isTabActive("/ordermanagement")}>
            <div className="title">
              <Link to="/ordermanagement" className="link">
                <i className="bi bi-basket2"></i>
                <span className="name">Order Management</span>
              </Link>
            </div>
          </li>
          <li className={isTabActive("/billingdetails")}>
            <div className="title">
              <Link to="/billingdetails" className="link">
                <i className="bi bi-receipt-cutoff"></i>
                <span className="name">Billing Management</span>
              </Link>
            </div>
          </li>
          <li className={isTabActive("/shippingdetails")}>
            <div className="title">
              <Link to="/shippingdetails" className="link">
                <i className="bi bi-cart4"></i>
                <span className="name">Shipping Management</span>
              </Link>
            </div>
          </li>

          <li className={isTabActive("/careerDetails")}>
            <div className="title">
              <Link to="/careerDetails" className="link">
                <i className="bi bi-briefcase"></i>
                <span className="name">Career Management</span>
              </Link>
            </div>
          </li>

          <li className={isTabActive("/contactUsDetails")}>
            <div className="title">
              <Link to="/contactUsDetails" className="link">
                <i className="bi bi-person-lines-fill"></i>
                <span className="name">Contact Management</span>
              </Link>
            </div>
          </li>

          <li className={isTabActive("/enquireDetails")}>
            <div className="title">
              <Link to="/enquireDetails" className="link">
                <i className="bi bi-info-square"></i>
                <span className="name">Enquiry Management</span>
              </Link>
            </div>
          </li>
          <li className={isTabActive("/teamEdit")}>
            <div className="title">
              <Link to="/teamEdit" className="link">
                <i class="bi bi-people-fill"></i>{" "}
                <span className="name">Team Management</span>
              </Link>
            </div>
          </li>

          <hr />
          <center>
            <h5>CMS Section</h5>
          </center>
          <hr />

          <li
            className={`dropdown ${activeSubmenu === "page" ? "active" : ""}`}
            onClick={() => {
              toggleSubmenu("page");
            }}
          >
            <div className="title">
              <Link className="link">
                <i className="bi bi-file-earmark"></i>
                <span className="name">Page Management</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu text-center">
              <Link className="link submenu-title">Page Management</Link>
              <Link to="/homecmsAdd" className="link">
                Home Page
              </Link>
              <Link to="/aboutcmsUpdate" className="link">
                About Page
              </Link>
              <Link to="/aboutcmsadd" className="link">
                About More
              </Link>
              <Link to="/manufactureadd" className="link">
                Manufacturing Page
              </Link>
              <Link to="/manufacturingcmsadd" className="link">
                Manufacturing More
              </Link>
              <Link to="/policycmseditor" className="link">
                Policy Pages
              </Link>
              <Link to="/footeredit" className="link">
                Footer
              </Link>
            </div>
          </li>
          <li className={isTabActive("/faq")}>
            <div className="title">
              <Link to="/faq" className="link">
                <i className="bi bi-gear-fill"></i>
                <span className="name">FAQs Management</span>
              </Link>
            </div>
          </li>

          <hr />
          <li>
            <div className="title">
              <Link onClick={adminLogout} className="link">
                <i className="bi bi-door-closed"></i>
                <span className="name">Logout</span>
              </Link>
            </div>
          </li>
          <li className="side-profile d-flex">
            <div className="profile-img">
              <img src="assets/profile.png" alt="" />
            </div>
            <div className="side-name">
              <h3>{adminname}</h3>
              <h5>{adminemail}</h5>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminSidebar;
