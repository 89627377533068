import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../AuthContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIns, setIsLoggedIns] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIns(!!token);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const userId = localStorage.getItem('userId');
  const location = useLocation();
  return (
    <>
     <nav className="z-20 flex items-center justify-between flex-wrap p-2 sticky top-0 bg-white shadow-md drop-shadow-[0_4px_45px_rgba(0,0,0,0.25)]">
  <div className="container flex items-center justify-between flex-wrap">
    <div className="flex items-center flex-shrink-0 text-white">
      <img
        src={`${process.env.PUBLIC_URL}/imgs-alu/logo.png`}
        className="w-[110px] mr-2"
        alt="Logo"
      />
    </div>
    <div className="block lg:hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
      >
        <svg
          className={`fill-current h-5 w-5 ${isOpen ? "hidden" : "block"}`}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
        <svg
          className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
        </svg>
      </button>
    </div>
    <div
      className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}
    >
      <div className="nav-list lg:flex lg:justify-center lg:flex-grow lg:mt-3 pt-2">
        <li className="list-none lg:inline-block">
          <Link
            to="/"
            className={`block lg:mx-2 sm:my-5 lg:inline-block lg:mt-0 text-base font-poppins ${
              location.pathname === '/' ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            Home
          </Link>
        </li>
        <li className="nav-item list-none lg:inline-block">
          <Link
            to="/about"
            className={`block lg:mx-2 sm:my-5 lg:inline-block lg:mt-0 text-base font-poppins ${
              location.pathname.startsWith('/about') ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            About Us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </Link>
          <div className="dropdown-menu">
            <ul>
              <li>
                <Link to="/why-us?pageType=Why%20Us">Why Choose us</Link>
              </li>
              <li>
                <Link to="/company?pageType=Our%20Company">Our Company</Link>
              </li>
              <li>
                <Link to="/mission?pageType=Our%20Mission">Our Mission</Link>
              </li>
              <li>
                <Link to="/vision?pageType=Our%20Vision">Our Vision</Link>
              </li>
              <li>
                <Link to="/team??pageType=Our%20Team">Our Team</Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="list-none lg:inline-block">
          <Link
            to="/products"
            className={`block lg:mx-2 sm:my-5 lg:inline-block lg:mt-0 text-base font-poppins ${
              location.pathname === '/products' ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            Products
          </Link>
        </li>
        <li className="nav-item list-none lg:inline-block">
          <Link
            to="/manufacturing"
            className={`block lg:mx-2 sm:my-5 lg:inline-block lg:mt-0 text-base font-poppins ${
              location.pathname.startsWith('/manufacturing') ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            Manufacturing
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </Link>
          <div className="dropdown-menu">
            <ul>
              <li>
                <Link to="/extrusion?pageType=Extrusion">Extrusion</Link>
              </li>
              <li>
                <Link to="/anodizing?pageType=Anodizing">Anodizing</Link>
              </li>
              <li>
                <Link to="/powder?pageType=Powder%20Coating">Powder Coating</Link>
              </li>
              <li>
                <Link to="/billet?pageType=Billet%20Making">Billet Making</Link>
              </li>
              <li>
                <Link to="/toolroom?pageType=Tool%20Room">Tool Room</Link>
              </li>
              <li>
                <Link to="/fabrication?pageType=Fabrication">Fabrication</Link>
              </li>
              <li>
                <Link to="/coloring?pageType=Coloring">Coloring</Link>
              </li>
              <li>
                <Link to="/designing?pageType=Designing">Designing</Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="list-none lg:inline-block">
          <Link
            to="/career"
            className={`block lg:mx-2 sm:my-5 lg:inline-block lg:my-0 text-base font-poppins ${
              location.pathname === '/career' ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            Career
          </Link>
        </li>
        <li className="list-none lg:inline-block">
          <Link
            to="/contact"
            className={`block lg:mx-2 sm:my-3 lg:inline-block lg:mt-0 text-base font-poppins ${
              location.pathname === '/contact' ? 'text-blue font-medium underline decoration-black' : 'text-dark no-underline'
            }`}
          >
            Contact Us
          </Link>
        </li>
      </div>
      <div className="inline-flex items-center nav-buttons">
        <Link to="/product-list">
          <button className="first-btn me-3">Catalogue</button>
        </Link>
        {isLoggedIns ? (
          <div className="flex items-center nav-item">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/imgs-alu/profile.png`}
                alt=""
                className="w-10 h-10 mr-2"
              />
            </div>
            <div>
              <h6 className="font-medium text-base font-poppins pt-2">
                {localStorage.getItem("name") ?? ""}
              </h6>
            </div>
            <div className="ml-2 cursor-pointer">
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="dropdown-menu">
              <ul>
                <li>
                  <Link to={`/profile/${userId}`}>My Profile</Link>
                </li>
                <li>
                  <Link to="/cart">My Cart</Link>
                </li>
                <li>
                  <Link to="/orders">My Orders</Link>
                </li>
                <li>
                  <Link onClick={logout}>Logout</Link>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <Link to="/login">
            <button className="second-btn">Login</button>
          </Link>
        )}
      </div>
    </div>
  </div>
</nav>

      <a href="https://wa.me/8114848666" className="float" target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/imgs-alu/whtsp.png`} alt="WhatsApp" />
      </a>

    </>
  );
};

export default Navbar;
