import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./components/pages/Landing";
import About from "./components/pages/About";
import WhyUs from "./components/pages/WhyUs";
import Company from "./components/pages/Company";
import Mission from "./components/pages/Mission";
import Vision from "./components/pages/Vision";
import Manufacturing from "./components/pages/Manufacturing";
import Extrusion from "./components/pages/Extrusion";
import Powder from "./components/pages/Powder";
import ToolRoom from "./components/pages/ToolRoome";
import Coloring from "./components/pages/Coloring";
import Register from "./components/loginReg/Register";
import OtpVerification from "./components/loginReg/OtpVerify";
import Login from "./components/loginReg/Login";
import Forgot from "./components/loginReg/Forgot";
import Reset from "./components/loginReg/Reset";
import Profile from "./components/pages/Profile";
import Product from "./components/pages/Product";
import AdminDashboard from "./components/admin/AdminDashboard";
import CustomerDetails from "./components/admin/CustomerDetails";
import ProductsDetails from "./components/admin/ProductsDetails";
// import FAQs from "./components/admin/FAQs";
// import FAQsAdd from "./components/admin/FAQsAdd";
// import FAQsEdit from "./components/admin/FAQsEdit";

// import AboutCmsPageEdit from "./components/admin/AboutCmsPageEdit";
import HomeCmsPageAdd from "./components/admin/HomeCmsPageAdd";
import AboutCmsPageEdit from "./components/admin/AboutCmsPageEdit";
import AboutCmsPageDetails from "./components/admin/AboutCmsPageDetails";
import ManufactureMain from "./components/admin/Manufactureimgadd";
import ManufacturingCmsPageAdd from "./components/admin/ManufacturingCmsPageAdd";
import PolicyCmsPageEditor from "./components/admin/CMSPolicyPageEditor";
import FAQPage from "./components/admin/faqupdate"
import Footer from "./components/admin/footerdetails"
// import AboutCmsPageAdd from "./components/admin/AboutCmsPageAdd";

import PlanPriceDetails from "./components/admin/PlanPriceDetails";
import PlanPriceEdit from "./components/admin/PlanPriceEdit";
import PlanPriceAdd from "./components/admin/PlanPriceAdd";

import OrderManagement from "./components/admin/OrderManagement";
import ShippingDetail from "./components/admin/ShippingDetails";

import ProductList from "./components/pages/ProductList";
import ProductDetail from "./components/pages/ProductDetail";
import ProductEdit from "./components/admin/ProductEdit";
import ProductAdd from "./components/admin/ProductAdd";
import Designing from "./components/pages/Designing";
import Cart from "./components/pages/Cart";

import Career from "./components/pages/Career";
import CareerDetails from "./components/admin/CareerDetails";
import BillingDetails from "./components/admin/BillingDetails";
import EnquireDetails from "./components/admin/EnquireDetails";
import ContactUsDetails from "./components/admin/ContactUsDetails";
import CareerEdit from "./components/admin/CareerEdit";
import BillingEdit from "./components/admin/BillingEdit";
import ContactUsEdit from "./components/admin/ContactUsEdit";
import EnquireEdit from "./components/admin/EnquireEdit";

import Catalogue from "./components/pages/Catalogue";
import Contact from "./components/pages/Contact";
// import Test from "./components/pages/Test";
import Specification from "./components/pages/Specification";
import Billing from "./components/pages/Billing";
import BillSummary from "./components/pages/BillSummary";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Refund from "./components/pages/Refund";
import Orders from "./components/pages/Orders";
import Thanks from "./components/pages/Thanks";
import Billet from "./components/pages/Billet";
import Fabrication from "./components/pages/Fabrication";
import Anodizing from "./components/pages/Anodizing";
import TeamList from "./components/admin/TeamList";
import OurTeam from "./components/pages/OurTeam";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/why-us" element={<WhyUs />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/mission" element={<Mission />} />
          <Route exact path="/vision" element={<Vision />} />
          <Route exact path="/team" element={<OurTeam />} />
          <Route exact path="/manufacturing" element={<Manufacturing />} />
          <Route exact path="/extrusion" element={<Extrusion />} />
          <Route exact path="/powder" element={<Powder />} />
          <Route exact path="/toolroom" element={<ToolRoom />} />
          <Route exact path="/coloring" element={<Coloring />} />
          <Route exact path="/designing" element={<Designing />} />
          <Route exact path="/billet" element={<Billet />} />
          <Route exact path="/fabrication" element={<Fabrication />} />
          <Route exact path="/anodizing" element={<Anodizing />} />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/verify-otp" element={<OtpVerification />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/profile/:userId" element={<Profile />} />

          <Route exact path="/products" element={<Product />} />
          <Route exact path="/product-list" element={<ProductList />} />
          <Route exact path="/product-detail/:productId" element={<ProductDetail />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/specification" element={<Specification />} />
          <Route exact path="/billing" element={<Billing />} />
          <Route exact path="/bill-summary" element={<BillSummary />} />


          <Route exact path="/career" element={<Career />} />
          <Route exact path="/catalogue" element={<Catalogue />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/thanks" element={<Thanks />} />
          
          
          <Route exact path="/adminDashboard" element={<AdminDashboard />} />
          <Route exact path="/customerDetails" element={<CustomerDetails />} />
          <Route exact path="/productsDetails" element={<ProductsDetails />} />
          <Route exact path="/productEdit/:productId" element={<ProductEdit />} />
          <Route exact path="/productAdd" element={<ProductAdd />} />
          <Route exact path="/ordermanagement" element={<OrderManagement />} />
          <Route exact path="/shippingdetails" element={<ShippingDetail />} />
          {/* <Route exact path="/faqs" element={<FAQs />} />
          <Route exact path="/faqsAdd" element={<FAQsAdd />} />
          <Route exact path="/faqsEdit/:faqsId" element={<FAQsEdit />} />           */}
          <Route exact path="/planPriceDetails" element={<PlanPriceDetails />} />
          <Route exact path="/planPriceAdd" element={<PlanPriceAdd />} />
          <Route exact path="/planPriceEdit/:planId" element={<PlanPriceEdit />} />

          <Route exact path="/homecmsAdd" element={<HomeCmsPageAdd />} />
          <Route exact path="/aboutcmsadd" element={<AboutCmsPageDetails />} />
          <Route exact path="/manufactureadd" element={<ManufactureMain/>} />
          <Route exact path="/manufacturingcmsadd" element={<ManufacturingCmsPageAdd/>} />
          <Route exact path="/policycmseditor" element={<PolicyCmsPageEditor/>} />
          <Route exact path="/faq" element={<FAQPage/>} />
          <Route exact path="/footeredit" element={<Footer/>} />
          {/* <Route exact path="/aboutcmsUpdate" element={<AboutCmsPageEdit />} /> */}
          {/* <Route exact path="/aboutcmsAdd" element={<AboutCmsPageAdd />} /> */}
          <Route exact path="/aboutcmsUpdate" element={<AboutCmsPageEdit />} /> {/* main about */}

          <Route exact path="/careerDetails" element={<CareerDetails />} />
          <Route exact path="/billingdetails" element={<BillingDetails />} />
          <Route exact path="/enquireDetails" element={<EnquireDetails />} />
          <Route exact path="/contactUsDetails" element={<ContactUsDetails />} />
          <Route exact path="/careerEdit/:careerId" element={<CareerEdit />} />
          <Route exact path="/billingEdit/:billingId" element={<BillingEdit />} />
          <Route exact path="/contactEdit/:contactId" element={<ContactUsEdit />} />
          <Route exact path="/enquireEdit/:enquireId" element={<EnquireEdit />} />
          <Route exact path="/teamEdit" element={<TeamList />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
