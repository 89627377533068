import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../AuthContext";

const OurTeam = () => {
  const [directors, setDirectors] = useState([]); // Store multiple directors
  const [teamMembers, setTeamMembers] = useState([]); // Store multiple team members
const { apipath } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/team/directors/getall`);
        // const response = await axios.get(`http://localhost:4000/api/v1/team/directors/getall`);
        if (response.data && response.data.length > 0) {
          setDirectors(response.data); // Set the array of directors
        }
        console.log("Response Data:", response.data);
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${apipath}/team/member/getall`);
        // const response = await axios.get(`http://localhost:4000/api/v1/team/member/getall`);
        if (response.data && response.data.teamMembers.length > 0) {
          setTeamMembers(response.data.teamMembers); // Set the array of team members
        }
        console.log("Team Members Data:", response.data);
      } catch (error) {
        console.error("Error fetching team members data:", error);
      }
    };
    fetchTeamMembers();
  }, []);
  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <Link to="/" className="float2" title="Help">
        <img src="imgs-alu/help.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Our Team
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Our Team
          </h4>
        </div>
      </div>

      {/* Directors Section */}
      {directors.length > 0 ? (
        directors.map((director) => (
          <div key={director._id} className="container mx-auto py-12 px-6">
            <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between bg-gray-100 p-6 rounded-lg shadow-lg">
              {/* Director Image and Designation */}
              <div className="flex flex-col items-center">
                <div className="w-40 h-40 rounded-full overflow-hidden border-4 border-gray-300">
                  <img
                    src={`https://narayanaluminium.com/backend${director.image}`} // Image URL
                    alt={director.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <p className="text-lg font-semibold mt-4">{director.designation}</p>
                <p className="text-m font-semibold mt-6" style={{ marginTop: "-15px" }}>{director.email}</p>
              </div>

              {/* Director Name and Message */}
              <div className="mt-6 md:mt-0 md:ml-6 flex-1">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  {director.name}
                </h2>
                <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
                  <p className="text-gray-700 leading-relaxed">{director.message}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-gray-500 py-12">Loading Directors...</div>
      )}

     {/* Team Members Section */}
     <div className="container mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Our Team Members
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {/* Team Member */}
          {teamMembers.length > 0 ? (
            teamMembers.map((member) => (
              <div
                key={member._id}
                className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center"
              >
                <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300 mb-4">
                  <img
                    src={`https://narayanaluminium.com/backend${member.image}`} // Image URL
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {member.name}
                </h3>
                <p className="text-sm text-gray-600">{member.designation}</p>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 py-12">Loading Team Members...</div>
          )}
        </div>
      </div>

      {/* <EnquiryForm /> */}
      <Footer />
    </>
  );
};

export default OurTeam;
